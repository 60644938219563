var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
export var Home = function () {
    return (_jsx(Box, { children: _jsx(Card, __assign({ sx: {
                py: 2,
                px: 6,
            } }, { children: _jsx(Grid, __assign({ container: true }, { children: _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(Typography, __assign({ align: "center", variant: "h5", gutterBottom: true }, { children: "In\u00EDcio" })), _jsx("hr", {}), _jsx(Typography, __assign({ component: "div" }, { children: _jsx(Box, __assign({ fontWeight: "bold" }, { children: _jsx(Typography, __assign({ align: "center", gutterBottom: true }, { children: "P\u00E1gina inicial" })) })) }))] })) })) })) }));
};

import { createElement, useCallback } from "react";
import { Menu } from "./Menu";
import { useNavigate } from "react-router-dom";
export var MenuContainer = function () {
    var history = useNavigate();
    var onClickItem = useCallback(function (key) {
        history(key);
    }, [history]);
    var props = {
        onClickItem: onClickItem,
    };
    return createElement(Menu, props);
};

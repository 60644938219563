var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MenuContainer } from "./modules/menu/Menu.container";
import { Grid } from "@mui/material";
import { RouterManager } from "./modules/router/RouterManager";
export var App = function () {
    return (_jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, md: 4 }, { children: _jsx(MenuContainer, {}) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 8 }, { children: _jsx(RouterManager, {}) }))] })));
};
